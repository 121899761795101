import { Observable } from 'rxjs';
import {
  ActivityTicketCreateDto,
  ActivityTicketDto,
  ActivityTicketEditDto,
  ActivityTicketPageDto,
  ActivityTicketPageQueryParamsDto,
} from '../dtos/activity-ticket.dto';

export abstract class ActivityTicketRepository {
  abstract getPage(
    params: ActivityTicketPageQueryParamsDto
  ): Observable<ActivityTicketPageDto>;
  abstract getAll(): Observable<ActivityTicketPageDto>;
  abstract create(
    activityTicket: ActivityTicketCreateDto
  ): Observable<ActivityTicketDto>;
  abstract update(
    activityTicket: ActivityTicketEditDto
  ): Observable<ActivityTicketDto>;
  abstract delete(activityTicket: {
    id: number;
  }): Observable<ActivityTicketDto>;
}
